// Here you can add other styles
body{
  background: #f7f8f9;
}
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
  }
}

.slideOutUp {
  animation-name: slideOutUp;
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}
.op-0{
  opacity: 0;
}
a:hover{
  text-decoration: none;
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
.main .container-fluid{
  padding: 0px 15px;
}
.img-avatar{
  border-radius: 0em;
}
.img-avatar.circle{
  border-radius: 2em;
}
.app-footer{
  position: fixed;
  bottom: 0px;
}
.btn{
  padding: 0.075rem 0.75rem;
  padding-top: 0px;
}
.inline-flex{
  display: inline-flex;
  overflow: auto;
  scroll-behavior: smooth;
}
.trainer-div{
  margin-right: 15px;
}
.btn-see-more, .btn-all-cities-dashboard{
  color: #ffffff;
  background-color: #20a8d8;
}
.font-12{
  font-size: 12px;
}
.collapse-card, .collapse-card .card-header{
  background: transparent;
    border: none;
}
.collapse-card .card-header{
  border-bottom: 1px solid #cccccc;
    border-radius: 0;
    padding: 0px;
}
.loader-text{
  position: absolute;
  bottom: 30px;
  color: #ffffff;
  padding-left: 15px;
  min-height: 330px;
  padding-bottom: 80px;
}
.scale-image{
  -webkit-transform: scale(1.07);
    transform: scale(1.07);
}
.hide{
  display: none;
}
.vis-none{
  visibility: hidden;
}
.box-shadow-none{
  box-shadow: none;
}
.news-letter{
  background: none !important;
    position: fixed !important;
    left: 0 !important;
    top: auto !important;
    right: auto !important;
    bottom: 0px !important;
    z-index: 10000 !important;
    padding: 1.2rem !important;
}
.newsletter-modal{
  min-width: 250px;
  max-width: 250px;
  border-radius: 15px;
}
.training-reg-btn{
  position: absolute;
    bottom: 10px;
    left: 0px;
    right: 0px;
}
.overlay{
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 1;
  cursor: pointer;
  user-select: none;
  position: absolute;
  border-radius: 25px;
}

.mobile-overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.6);
  user-select: none;
  cursor: pointer;
  position: fixed;
}

.fixed-image-background {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.6);
  user-select: none;
  cursor: pointer;
  position: fixed;
  background: no-repeat;
  background-size: cover;
}

.trainer-div .overlay{
  background: rgba(0, 0, 0, 0.1);
}
.overlay-div{
  z-index: 2;
}
.bold{
  font-weight: 600;
}
.mb-8{
  margin-bottom: 8rem;
}
.p-l-0{
  padding-left: 0px !important;
}
.p-l-25{
  padding-left: 25px;
}
ol li{
  margin-bottom: 5px;
}
a{
  color: #000000;
}

/*Desktop CSS*/
header.app-header.desktop-header{
  height: 55px;
}
.center{
  float: none;
    margin: auto;
}
.nav-pills .nav-link.active.new-links{
  background-color: transparent;
  padding: 7px 20px;
}
ul.linear-grad{
  background-image: linear-gradient(to right, rgb(0,190,218), rgb(48,23,255) );
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
}
.nav-pills .nav-link.active.blue-links{
  max-height: 43px;
  /*padding: 15px 30px;*/
  background-color: transparent;
  border-right: 1px solid #ffffff;
  border-radius: 0px;
}
.nav-pills .nav-link.active.blue-links.first-link, .nav-pills .nav-link.active.blue-links.second-link{
  border-right: 0px;
}
.nav-pill-div{
  background: transparent;
    border: 0px;
    padding: 0px;
}
.nav-pill-div .card-body{
    padding: 45px;
}
.nav-item.first{
  border: 1px solid #ffffff;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
    border-color: #ffffff;
}
.nav-item.first.desktop{
  border-right: 1px solid #ffffff;
}
.nav-item.first.desktop, .nav-item.last.desktop {
  border: 0px;
}
.nav-item.first.desktop .nav-link.active.blue-links{
  border-right: 1px solid #ffffff;
}
.nav-item.last.desktop .nav-link.active.blue-links{
  border-right: 0px;
}
.nav-item.last{
  border: 1px solid #ffffff;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    border-color: #ffffff;
}
.nav-item.main-navs{
  border: 1px solid #ffffff;
  background-color: rgba(243, 240, 240, 0.15);
  font-weight: bold;
  max-height: 37px;
}
.btn-outline-primary.btn-csm-desktop{
  background: #ffffff;
  color: #361d98;
  border: #361d98;
  padding: 5px 35px;
}
.btn-outline-primary.btn-icagile-desktop{
  background: #ffffff;
  color: #361d98;
  border: #361d98;
  padding: 5px 35px;
}
.btn-outline-primary.btn-cspo-desktop{
  background: #ffffff;
  color: #361d98;
  border: #361d98;
  padding: 5px 35px;
}
.btn-outline-primary.btn-tkp-desktop{
  background: #ffffff;
  color: #361d98;
  border: #361d98;
  padding: 5px 35px;
}
.desk-training-div .overlay{
  border-radius: 0px;
  width: 98%;
}
.desk-training-div{
  min-height: 200px;
}
.search-course-card {
  min-height: 244px;
  min-width: 212px;
}
.training-reg-btn-desktop{
  position: absolute;
  bottom: 15px;
  left: 0;
}
.big-size{
  font-size: 3rem;
}
.nav-pill-div .card-body.p-l-0{
  padding-left: 0px;
  padding-right: 0px;
}
.nav-pill-div .card-body.scroll-div{
  padding: 0px;
}
.nav-pill-div .card-body.scroll-div.dynamic-width{
  width: calc(100% - 66px);
  li.nav-item.active {
    width: calc((100% - 17px)/6);
  }
  .nav-pills .nav-link.active.blue-links{
    /*padding: 11px 26px;*/
    text-align: center;
    max-height: 43px;
    min-height: 43px;
    white-space: nowrap;
    padding-top: .7rem;
  }
}
@media screen and (max-width: 1274px){
  .nav-pill-div .card-body.scroll-div.dynamic-width .nav-pills .nav-link.active.blue-links{
    /*padding: 15px 0px;*/
  }
}
.black{
  color: #000000 !important;
}
.font-5{
  font-size: 5rem;
}
.table{
  display: table;
  float: none;
}
.table-cell{
  display: table-cell;
  float: none;
}
.text-card{
    color: #ffffff;
}
.bg-grad{
  background : -moz-linear-gradient(-2.73% 2.46% -45deg,rgba(0, 191, 212, 1) 0%,rgba(229, 127, 255, 0) 100%);
    background : -webkit-linear-gradient(-45deg, rgba(0, 191, 212, 1) 0%, rgba(229, 127, 255, 0) 100%);
    background : -webkit-gradient(linear,-2.73% 2.46% ,102.73% 97.54% ,color-stop(0,rgba(0, 191, 212, 1) ),color-stop(1,rgba(229, 127, 255, 0) ));
    background : -o-linear-gradient(-45deg, rgba(0, 191, 212, 1) 0%, rgba(229, 127, 255, 0) 100%);
    background : -ms-linear-gradient(-45deg, rgba(0, 191, 212, 1) 0%, rgba(229, 127, 255, 0) 100%);
}
.search-desktop{
  background: transparent !important;
  border: 1px solid #e4e7ea;
  border-right: 0px;
  color: #ffffff !important;
  border-radius: 25px 0px 0px 25px !important;
}
.desk-input-group{
  background-image: linear-gradient(to right, #00beda, #3017ff) !important;
  border-radius: 30px;
  border: 0px;
  max-height: 43px;
}
.desk-search-input{
  border-left: 0px;
  height: auto;
}
.curved-input.desk-search-input{
  border-radius: 0px 25px 25px 0px !important;
}
input[type='text'].desk-search-input::placeholder{
  color: #ffffff;
}
.btn-pill.lp-blue-grad{
  color: #ffffff;
}
#menu:focus{
  outline: none;
}
.app-header .nav-item:focus{
  outline: none;
}
.app-header .nav-item button:focus{
  outline: none;
}
.p-chat{
  padding: 1rem;
}
.m-l-10{
  margin-left: 10px;
}
.p-pad{
  padding: 5px 24px 5px 25px;
}
.inline-flex-without-scroll{
  height: 70vh;
    display: flex;
    align-items: flex-end;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 50px;
    min-height: 580px !important;
    scroll-behavior: smooth;
}

.inline-flex-mobile-modal{
  /*height: 70vh;*/
  display: flex;
  align-items: flex-start;
  overflow-x: scroll;
  overflow-y: hidden;
  /*min-height: 580px !important;*/
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.inline-flex-simple-scroll{
  height: 70vh;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 50px;
    min-height: 580px !important;
    scroll-behavior: smooth;
}
.inline-flex-without-scroll::-webkit-scrollbar {
    display: none;
}
div,section{
  ::-webkit-scrollbar ,::-moz-scrollbar,::-o-scrollbar,::-ms-scrollbar {
      display: none;
  }
}
.inline-flex-without-scroll-mobile{
    display: flex;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 15px;
    min-height: 200px !important;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

.inline-flex-without-scroll-cities{
  max-height: 150px;
  display: flex;
  align-items: flex-start;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-top: 15px;
  scroll-behavior: smooth;
}

.inline-flex-without-scroll-mobile::-webkit-scrollbar {
    display: none;
}
.yellow{
  color: yellow;
}
.support-message::placeholder  {
  font-size: 25px;
  padding-top: 2rem;
  text-align: center;
}
.m-t-6{
  margin-top: 6rem;
}
.time-card{
  border-radius: 15px;
    border: 0px;
    box-shadow: 0 0px 5px 2px rgba(0, 0, 0, 0.1);
}
.normal{
  display: inline;
    font-weight: 300;
}
.card.city-card:hover{
  transform: scale(1.03);
  z-index: 3;
}
.chat-div{
  margin-right: 10px;
}
.box-shadow{
  box-shadow: 0 1px 4px rgba(0,0,0,.1);
}
.br-tc{
  border-right: 1px solid #cccccc;
    text-align: center;
}
.slide-div{
  min-height: 300px;
}
.slide-div .carousel.slide{
  min-height: 200px;
}
.pointer{
  cursor: pointer;
}
.pointer_auto {
  cursor: auto;
}
.lp-rev-pal-grad{
  background: linear-gradient(to right, rgb(255,191,250) ,rgb(135,225,255) );
}
.lp-training-div{
  background: linear-gradient(to right, rgb(34,197,190) ,rgb(0,126,255) );
}
.bg-grad-trainer{
  background: linear-gradient(90deg, #63cbd6 0%, #a6dcd1 100%);
}
.font-3{
  font-size: 3rem;
}
.trainer-heading{
  color: rgb(1,85,78);
}
.w-100{
  width: 100%;
}
.m-05{
  margin: 0.5rem;
}
.mobile-card{
  background: #ffffff;
  box-shadow: 0 0px 10px 2px rgba(0, 0, 0, 0.1);
}
.blue-color{
  color: #00c0ff;
}
.agenda-class{
  background-color: #2e2b83;
}
.text-absolute-why{
  position: absolute;
    bottom: 0px;
}
.font-2{
  font-size: 2rem;
}
.w-25{
  width: 25px !important;
}
.font-1rem{
  font-size: 1rem;
}
.m-b-0{
  margin-bottom: 0px;
}
.m-l-35{
  margin-left: 35px;
}
.fixed-div{
    z-index: 998;
    background: hotpink;
  position: fixed;
  top: 55;
}
#city-selection-activate-on-scroll{
  // display: none;
  width: 100%;
  position: fixed;
  top: -170px;
  z-index: 998;
  // z-index: -1;
  will-change: top;
  -webkit-transition: top 1s; /* Safari */
  transition: top 1s;
  #m-t-div-2,#m-t-div-3,#m-t-div-1,.post-select.row{
    width: 100%;
  }
}
.height-0{
  height: 0px;
}
.agenda-list li::before {
  content: "•";
  font-size:1.5rem;
  color: #00aaff;
  font-weight: bold;
  display: inline-block;
  width: .8em;
}
.our-agenda{
  background: linear-gradient(90deg,#00a2d4,#4d3fff);
}
.m-t-fixed-div{
  margin-top: 190px;
}
#csm_collapsed{
  // display: none;
  width: 100%;
  position: fixed;
  will-change: top;
  top: -31px;
  z-index: 998;
  -webkit-transition: top 1s; /* Safari */
  transition: top 1s;
}
.input-material, select.input-material{
  border-radius: 0px !important;
    border: 0px !important;
    border-bottom: 1px solid #ffffff !important;
    background: transparent;
    color: #ffffff;
    background-color: transparent !important;
}
input[type='text'].input-material::placeholder, select.input-material::placeholder{
  color: #ffffff;
}
input[type='email'].input-material::placeholder{
  color: #ffffff;
}
input[type='email'].input-material:focus{
  background: transparent;
  box-shadow: none;
  color: #ffffff;
}
input[type='text'].input-material:focus, select.input-material:focus{
  background: transparent;
  box-shadow: none;
  color: #ffffff;
}
.modal-refer{
  width: 600px;
  top: 2rem;
}
.white-color-text-btn{
  background: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 25px;
}
.check-icon{
  position: absolute;
  right: -10px;
  top: 0px;
  font-size: 30px;
}
.padright{
  // padding-right: 15px
  margin: 0px 60px;
  max-width: 75%;
}

/* Carousel CSS
============================= */
.carousel {
  position: relative;
  width: 100%; }
.carousel * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
.carousel img {
  width: 100%;
  display: inline-block;
  pointer-events: none; }
.carousel .carousel {
  position: relative; }
.carousel.carousel-slider {
  position: relative;
  margin: 0;
  overflow: hidden; }
.carousel.carousel-slider {
  top: 0;
  color: #fff;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: -5px; }
.mob-carousel .carousel.carousel-slider {
  height: 300px;
}
.carousel .slider-wrapper {
  overflow: hidden;
  margin: auto;
  width: 100%;
  height: 100%;
  -webkit-transition: height 0.15s ease-in;
  -moz-transition: height 0.15s ease-in;
  -ms-transition: height 0.15s ease-in;
  -o-transition: height 0.15s ease-in;
  transition: height 0.15s ease-in; }
.carousel .slider-wrapper.axis-horizontal .slider {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex; }
.carousel .slider-wrapper.axis-horizontal .slider .slide {
  flex-direction: column;
  flex-flow: column; }
.carousel .slider {
  margin: 0;
  padding: 0;
  position: relative;
  list-style: none;
  width: 100%;
  height: 100%; }
.carousel .slider.animated {
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out; }
.carousel .slide {
  min-width: 100%;
  margin: 0;
  min-height: 200px;
  position: relative; }
.carousel .slide img {
  width: 100%;
  vertical-align: top;
  border: 0; }
.carousel .slide iframe {
   display: inline-block;
   /*width: calc(100% - 80px);*/
   width: 100%;
   /*margin: 0 40px 40px;*/
   border: 10px solid #000;
   border-radius: 10px;
   margin-bottom: -10px;
}
.carousel .slide .legend {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: absolute;
  bottom: 40px;
  left: 50%;
  margin-left: -45%;
  width: 90%;
  border-radius: 10px;
  color: #fff;
  padding: 10px;
  font-size: 12px;
  text-align: center;
  opacity: 0.25;
  -webkit-transition: opacity 0.35s ease-in-out;
  -moz-transition: opacity 0.35s ease-in-out;
  -ms-transition: opacity 0.35s ease-in-out;
  -o-transition: opacity 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out; }
.carousel .control-dots {
  position: absolute;
  bottom: 0;
  margin: 10px -26px;
  text-align: center;
  width: 100%; }
@media (min-width: 960px) {
  .carousel .control-dots {
  bottom: 0; } }
.carousel .control-dots .dot {
  -webkit-transition: opacity 0.25s ease-in;
  -moz-transition: opacity 0.25s ease-in;
  -ms-transition: opacity 0.25s ease-in;
  -o-transition: opacity 0.25s ease-in;
  transition: opacity 0.25s ease-in;
  opacity: 0.3;
  filter: alpha(opacity=30);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
  background: #000;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px; }
.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
  opacity: 1;
  filter: alpha(opacity=100); }
.carousel:hover .slide .legend {
  opacity: 1; }
.carousel .slider-wrapper {
  height: 100% !important;
}
.control-disabled.control-arrow {
  opacity: 0;
  filter: alpha(opacity=0);
  cursor: inherit; }
.content {
  height: 235px;
  overflow: inherit;
}
select.input-csm{
  border-radius: 0px !important;
  border: 0px !important;
  background: transparent;
  -webkit-appearance: initial;
  -moz-appearance: initial;
  -o-appearance: initial;
  -ms-appearance: initial;
  appearance: initial;
  color: yellow;
  padding-right: 30px;
  z-index: 1;
  position: relative;
  line-height: initial;
}
select option{
  color: #000000;
}
select.input-csm:focus{
    background: transparent;
    color: yellow;
  box-shadow: none;
}
select.input-csm:active,select.input-csm:hover,select.input-csm:visited,
select.input-csm:disabled,select.input-csm::-ms-expand,
select.input-csm:focus::-ms-value{
    background: transparent;
    color: yellow;
    box-shadow: none;
}
.csm-data-arrow{
  position: absolute;
  right: 10px;
  top: 15px;
}
.csm-post-data-arrow{
  position: absolute;
    right: 10px;
    top: 25px;
}
.training-scedule-div{
  background: rgb(242,242,242);
  border-radius: 5px;
  padding: 10px 5px;
}
.header-training-scedule-div{
  background: rgb(242,242,242);
  border-radius: 5px;
  padding: 6px 5px;
  max-height: 43px;
}
.m-0{
  margin: 0px;
}
.post-select{
  display: none;
}
.inline-block{
  display: inline-block;
}

.csm-card-modal{
  width: 100%;
  margin: 3em 0 0 0;
  max-width: 100%;
  height: 100vh;
}

.modal-open .csm-card-modal{
  overflow-y: hidden;
}

.csm-card-modal .modal-content{
  background: linear-gradient(to right, rgb(34,197,190) ,rgb(0,126,255) );
  height: 100%;
}

.csm-card-modal .modal-dialog{
  min-width: 100%;
  height: 100%;
  margin: 0;
}

.modal-component {
  background-color: white;
  min-width: 100%;
  height: 85vh;
  border-radius: 40px 40px 0 0;
  padding: 2em 2em 6em 2em;
  overflow-y: scroll;
  margin-right: .5em;
  margin-left: 0rem;
  display: inline-block;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
.modal-component.b-r-modal {
  border-radius: 35px;
}

.modal-component img,iframe{
  width: 100%;
}

.modal-row{
  padding-right: .5em;
  margin: 2.5em 0 0 0;
}

.modal-row .col-12{
  padding-left: .5em;
  padding-right: 0;
}
.div-with-arrow::after {
  font-size: 1.5rem;
  position: absolute;
    bottom: 5px;
    color: #ffffff;
    left: 44%;
  content: "\E604";
  font-family: 'simple-line-icons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.imag-for-card{
  margin-top: -16px;
}
.modal-close-button{
  font-size: 25px;
    position: absolute;
    right: 10px;
    color: #ffffff;
    top: 10px;
    z-index: 3;
}
.modal-inline .modal-component{
  min-width: 60vw;
  height: 70vh;
  margin-left: 20px;
  overflow-y: scroll;
  padding: 2rem 0rem;
}
.styles_closeButton__20ID4{
  color: #ffffff;
    background: #ffffff;
    border-radius: 50%;
}
.big-number{
  font-size: 12rem;
    font-weight: bold;
    margin-right: 4px;
    margin-top: -30px;
    opacity: 0.5;
}
.m-t-4{
  margin-top: 4rem;
}
.modal-close-button.black{
    color: #000000;
}
.tab-content{
  margin-top: 0;
  background: #fff;
  border: none;
}
 .mb-test{
   margin-bottom: 4rem;
 }
.test-bg{
  background: #fff;
}
.mob-test-pad{
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 1px;
  padding-left: 1px;
}
.nav-tab-border{
  border: none;
}
.nav-video-tab.nav-link {
  border: none;
  border-top-right-radius: 1.25rem;
  border-top-left-radius: 1.26rem;
  margin: 0 10px;
}
.nav-text-tab.nav-link {
  border: none;
  border-top-right-radius: 1.25rem;
  border-top-left-radius: 1.25rem;
  margin: 0 10px;
}
.p-faqs{
  padding: 1rem 0rem 1rem 0rem
}
.desk-nav {
  // margin: 30px -45px 0px;
  margin-top: inherit;
}
.testimonial-size {
  position: relative;
    top: 4rem;
}
.p-faqs{
  padding: 1rem 0rem 1rem 0rem
}
.overflow-right-div{
  height: 85vh;
    overflow: scroll;
    padding-left: 50px;
    padding-right: 30px;
    padding-top: 15px;
}
.transparent-bg{
  background: transparent !important;
}
.city-select-desktop,.city-select-desktop.btn-pill.lp-blue-grad.btn.btn-outline-primary{
  background: linear-gradient(to right, rgb(37,20,108) ,rgb(105,0,131) );
  border-radius: 25px;
}
.button-yellow{
  background: yellow;
    border-radius: 25px;
}

.tr-video{
  padding-bottom: 5px !important;
}
.tr-video.testimoni{
  margin-left: 15px;
}
.nav-link.tr-video.active{
  border-bottom: 1px solid #ffffff;
}
.training-page-desktop-modal{
  position: fixed;
    height: 90vh;
    z-index: 16;
    right: 0px;
    background: #ffffff;
    width: 57%;
    margin-left: -15px;
    top: 118px;
    overflow-y: scroll;
    display: none;
    border-radius: 20px;
  }
.allcity-page-desktop-modal{
  position: fixed;
    height: 87vh;
    z-index: 16;
    left: 0px;
    background: #ffffff;
    width: 50%;
  top: 15vh;
    overflow-y: scroll;
    display: none;
    border-radius: 0px;
}
.fontawesome-arrow {
  margin: 15rem 5px;
  background: -webkit-linear-gradient(left, #00d5ff ,#0072ff);
  width: 60px;
  height: 60px;
  padding: 14px;
  -webkit-border-radius: 50%;
  color: white;
  box-shadow: 12px 4px 10px 10px;
}
.fontawesome-arrow-trainer {
  margin-top: 4rem;
  background: -webkit-linear-gradient(left, #00d5ff ,#0072ff);
  width: 60px;
  height: 60px;
  padding: 14px;
  -webkit-border-radius: 50%;
  color: white;
  box-shadow: 12px 4px 10px 10px;
}
.testimonial-width {
  max-width: 83%;
  margin: 0px;
}
.testimonial-width-video {
  max-width: 80%;
  margin: 0px 0px;
}
.review-margin {
  margin: 3px 20px;
}
.quote-margin {
  margin: -15px 30px 0px 0px;
}
ul#menu-footer-menu{
  margin-left: 0;
    list-style: none;
    padding: 0px;
}
ul#menu-footer-menu li a, p.fot-sec a{
  color: #ffffff;
}
.footer-bg{
  background: rgb(32,31,81);
}
.nav-video-testimonial{
  border-radius: 15px;
  min-height: 200px;
  background: black;
}
.button-margin {
  margin-top: 6rem;
  -webkit-font-smoothing: subpixel-antialiased;
  color: white;
}
.nav-tab-size {
  // font-size: 1.5rem;
  margin-top: 0.5rem;
}
.padleft {
  padding-left: 17px;
}
.test-margin {
  padding-bottom: 0px !important;
}
.video-arrow {
  margin: 12rem -5px;
}
.trainer-pf-button{
  float: right;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    padding: 3px;
}
.border-0{
  border: none;
}
.why-text-video, .why-text-image{
  display: none;
}
.why-text-video.active, .why-text-image.active{
  display: block;
}
.trainer-test {
  margin-top: -32px;
  margin-left: 17px;
}
.mob-trainer-video {
  margin-top: 1rem;
}
.video-background {
  background-size: 300px 200px;
}
.gr-bg{
  background: #005532;
    height: 100vh;
}
.head-gr-bg{
  background: #009a3f;
  padding: 0px 0px 0px 10px;
  margin-left: -7px;
}
.btn-city-btn.green.active{
  /*padding: 10px 40px 10px 40px;*/
    background: #009a3f !important;
    border: 0px;
    /*border-radius: 0px 0px 0px 15px;*/
    /*border-bottom-left-radius: 3px solid #ffffff;*/
    /*margin-left: -11px;*/
    color: #ffffff !important;
}
.btn-city-btn.dash{
    color: #ffffff !important;
}
.btn-city-btn.dash.active{
    background: #2f353a !important;
}
.btn-city-btn.workshop{
    color: #ffffff !important;
}
.btn-city-btn.workshop.active{
    background: #73818f !important;
}
.video-text{
  display: none;
}
.video-text.active{
  display: block;
}
.trainer-inline-flex {
  height: 40vh;
  display: flex;
  align-items: flex-end;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 20px;
  min-height: 475px !important;
  scroll-behavior: smooth;
}
.trainer-header {
  margin-bottom: -40px;
}
.desk-image-buttons{
  position: absolute;
    bottom: 10px;
    text-align: center;
}
.desktop-btn-round{
  border-radius: 50%;
    height: 30px;
    width: 30px;
    padding: 3px;
}
.desk-video-btn{
  padding: 5px 30px 5px 30px;
    border-radius: 20px;
    background: #ffffff;
}
.margin-video {
  margin-top: 3rem;
}
.video-pad-bottom {
  padding-bottom: 10px;
}
.video-page-margin {
  margin-bottom: 5rem !important;
}
.bg-border{
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.bottom-button{
  position: absolute;
    bottom: -30px;
    width: 100px;
    left: 50%;
}
.div-with-min-height{
  height: 76vh;
    display: flex;
    align-items: flex-end;
    overflow-x: scroll;
}
.div-scroll-min-h{
  height: 70vh;
    overflow: scroll;
}
.btn-city-btn{
  padding: 10px 20px 10px 20px;
    border: 0px;
    border-radius: 15px 15px 0px 0px;
    background: transparent;
    font-weight: bold;
    white-space: nowrap;
}
.btn-city-btn.active{
  padding: 10px 20px 10px 20px;
    border: 0px;
    border-radius: 15px 15px 0px 0px;
    background: #00beda !important;
    color: #ffffff !important;
    font-weight: bold;
    white-space: nowrap;
}
.btn-secondary.btn-city-btn:not(:disabled).active:focus{
  box-shadow: none;
  outline: none;
  border: none;
}
.btn-secondary.btn-city-btn:focus{
  box-shadow: none;
  outline: none;
  border: none;
}
.b-r-0{
  border-radius: 0px;
}
.city-meetup{
  background-repeat: no-repeat;
    background-position: 100% 100%;
    background-color: rgb(155,111,254 );
    border-radius: 25px;
}
.meetup-div{
  background: #efefef;
    border-radius: 25px;
}
.text-color-blue{
  color: #006bc2;
}
.circle-image{
  height: 50px;
    width: 50px;
    border-radius: 50%;
}
.all-city-small-image{
  max-height: 50px;
    min-height: 120px !important;
}
.video-back {
  min-height: 138px;
  text-align: center;
}
.video-background-image {
  background-size: 100% 138px;
}
.video-test-size {
  max-height: 350px;
}
.video-div-size {
  min-width: 220px;
}
.video-button-margin {
  margin-top: 3rem;
  -webkit-font-smoothing: subpixel-antialiased;
  color: white;
}
.mobile-city-card-image{
  transition-timing-function: ease-in;
  transition: .2s;
  border-radius: 50%;
  box-shadow: 0px 1px 5px;
  width: 23vw;
  height: 23vw;
  max-height: 160px;
  max-width: 160px;
  border: 1px solid white;
}

.desktop-city-card-image{
  transition-timing-function: ease-in;
  transition: .2s;
  border-radius: 50%;
  box-shadow: 0px 1px 5px;
  width: 13vw;
  height: 13vw;
  max-height: 160px;
  max-width: 160px;
  border: 2px solid white;
}

.desktop-city-card-image:hover{
  transform: scale(1.1);
  z-index: 3;
}

.mobile-city-card-image:hover{
  transform: scale(1.1);
  z-index: 3;
}

.see-less-training{
  display: none;
}
.mob-see-less-training{
  display: none;
}
.trainer-video-test-margin {
  margin-bottom: 2rem !important;
}
.see-less-classroom-trainings {
  display: none;
}
.see-less-city{
  display: none;
}
.fixed-header-dashboard{
  position: fixed;
    top: 30px;
    left: 0px;
    z-index: 230;
    background: rgb(247, 248, 249);
    padding-top: 44px;
    padding-left: 75px;
    padding-bottom: 3px;
}
.inline-flex-simple-scroll.h-85{
  height: 85vh;
  align-items: flex-end;
}
.why-us-abs-button{
  position: absolute;
  top: -50px;
}
.why-text-button{
  position: absolute;
  top: 20px;
}
.search-modal .styles_closeButton__20ID4{
  top: 60px;
}
.why-us-abs-div{
  position: absolute;
  top: 20px;
}
.explore_video {
  max-height: 138px;
  margin: 95px 0 20px;
  max-width: 230px;
}
.explore_video_desc {
  padding-left: 30px;
}

.content {
  position: absolute;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  margin-top: -150px;
  right: 0px;
}
.content video {
  width: 100%;
  display: block;
}
.content:before {
  content: '';
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.btn-arrow-fixed{
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
}
.arrow {
  text-align: center;
  margin: 0 0;
}
.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}
.w-50{
  width: 50%;
}
.all_trainings.desktop{
  min-height: 300px;
}
.form-control.desk-search-input:disabled{
  background: transparent;
}
input[type='text'][name='global-search']{
  color: #ffffff;
  padding: 10px 10px;
}
.mob_see_less {
  margin-top: -30px;
}
.m-h-150{
  min-height: 150px;
}
.align-items-end{
  align-items: flex-end;
  display: flex;
}
.modal-login{
  width: 900px;
  border-radius: 15px;
  padding: 0px;
  max-width: 900px;
}
.login-logo{
  height: 100px;
    width: 100px;
}
.input-material-black, select.input-material-black{
  border-radius: 0px !important;
    border: 0px !important;
    border-bottom: 1px solid #cccccc !important;
    background: transparent;
    color: #cccccc;
}
input[type='text'].input-material-black::placeholder, select.input-material-black::placeholder{
  color: #cccccc;
}
.phone-mobile::placeholder {
  color: #5c6873;
}
input[type='text'].input-material-black:focus, select.input-material-black:focus{
  background: transparent;
  box-shadow: none;
}
input[type='email'].input-material-black::placeholder, select.input-material-black::placeholder{
  color: #cccccc;
}
input[type='email'].input-material-black:focus, select.input-material-black:focus{
  background: transparent;
  box-shadow: none;
}
input[type='password'].input-material-black::placeholder, select.input-material-black::placeholder{
  color: #cccccc;
}
input[type='password'].input-material-black:focus, select.input-material-black:focus{
  background: transparent;
  box-shadow: none;
}
.bg-blue-login{
  background: #00acf6;
}
.select-t-training{
  display: none;
}
.fixed-alert-box.card-body {
  position: fixed;
  top: 60px;
  right: 75px;
  z-index: 1020;
  padding-bottom: 0;
  @media (max-width: 576px) {
    right: 35px;
    padding: 0px;
  }
}
/*#signin_form{
  display: none;
}*/
#forgot_form{
  display: none;
}
.aside-div{
  position: fixed;
    height: 100vh;
}
.main-div{
  margin-left: 240px;
}
.flex-end-div{
  display: flex;
    align-items: flex-end;
    flex-direction: row;
}
.nav-link.tag-class.active{
  background: #ffffff;
}
.nav-link{
  cursor: pointer;
}
.video_pad_l {
  padding-left: inherit
}
.div-max-height{
  max-height: 70vh;
  overflow: scroll;
}
.desk_video_carousel {
  min-width: 200px;
  /*max-width: 1550px;*/
  min-height: 450px;
}
.desk_video_text_display {
  display: flex;
  align-items: flex-end;
}
.desk_video_right {
  position: absolute;
  z-index: 2;
  right: 10px;
}
.desk_video_left {
  position: absolute;
  z-index: 3;
  left: 0;
}
.desk_video_likes {
  bottom: 9px;
}
.pad_right {
  padding-right: 50px;
}
.desk_angle {
  z-index: 2;
  bottom: 115px;
}
.desk_video_top {
  padding-top: 4rem;
}
.video_desk_div {
  min-width: 300px;
  min-height: 200px;
  height: 100%;
  padding: 0px 0px;
  -webkit-transition: 1s ease-in;
  transition: 1s ease-in;
}
.desk_video_div {
  min-height: 200px;
  padding: 0px 0px;
}
.video_desk_div_bg {
  background-size: 100% 200px;
}
.video_desk_vid_div_bg {
  background-size: 100% 200px;
  border-radius: 15px;
}
.video_div_max {
  max-width: 260px;
}
.btn_arrow {
  margin-top: -65px;
}
.dash_video_mt {
  margin-top: 4rem !important;
}
.inline_flex_video {
  height: 38vh;
}
.p_video_div {
  margin: 15px;
  height: 41%;
}
.mt-20 {
  margin-right: 20px;
  margin-bottom: 30px;
  padding-left: 0px;
}
.overlay_video{
  width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1;
    cursor: pointer;
    user-select: none;
    position: absolute;
    -webkit-transition: width height 1s ease-in;
    transition: width height 1s ease-in;
}
.playlist_video {
  max-width: 45%;
  position: absolute;
  top: -2rem;
  right: 5rem;
}
.carousel_video {
  position: inherit;
  top: 0rem;
  left: -12rem;
}
.video_mt {
  // min-height: 425px;
  max-height: 425px;
  overflow-y: auto;
}
.m-t-2{
  margin-top: 2rem;
}
.p-r-0 {
  padding-right: 0px;
}
.video_arw {
  top: 35%;
}
.video_rarw {
  top: 36%;
}
.login-text-color{
  color: #cccccc;
}
.overflow-y-hidden {
  @media (max-width: 980px) {
    overflow-y: hidden;
  }
}
.csm-study{
  background: linear-gradient(to right, rgb(68,0,255) ,rgb(64,0,114) );
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  max-height: 300px;
  min-height: 200px;
  min-width: 400px;
  @media (max-width: 980px) {
    max-height: 280px;
    min-height: 200px;
    min-width: 300px;
  }
}
.csm-study.active{
  max-height: none;
}
.cspo-study{
  background: linear-gradient(to right, #0000ff ,#008eff );
}
.course-card-details {
  max-height: 266px;
    min-height: 266px;
    @media (max-width: 980px) {
    max-height: 246px;
  min-height: 192px;
  }
}
.view-details{
  position: absolute;
  bottom: 15px;
  @media (max-width: 980px) {
    bottom: 5px;
  }
}
.b-r-25{
  border-radius: 25px;
}
.lp-pal-pal-grad{
    background : -moz-linear-gradient(0% 50% 0deg,rgba(154, 127, 255, 1) 2.71%,rgba(254, 42, 255, 1) 100%);
    background : -webkit-linear-gradient(0deg, rgba(154, 127, 255, 1) 2.71%, rgba(254, 42, 255, 1) 100%);
    background : -webkit-gradient(linear,0% 50% ,100% 50% ,color-stop(0.0271,rgba(154, 127, 255, 1) ),color-stop(1,rgba(254, 42, 255, 1) ));
    background : -o-linear-gradient(0deg, rgba(154, 127, 255, 1) 2.71%, rgba(254, 42, 255, 1) 100%);
    background : -ms-linear-gradient(0deg, rgba(154, 127, 255, 1) 2.71%, rgba(254, 42, 255, 1) 100%);
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#9A7FFF', endColorstr='#FE2AFF' ,GradientType=0)";
    background : linear-gradient(90deg, rgba(154, 127, 255, 1) 2.71%, rgba(254, 42, 255, 1) 100%);
    color: #FFFFFF;
    border-radius : 16px;
    -moz-border-radius : 16px;
    -webkit-border-radius : 16px;
    filter: alpha(opacity=86) progid:DXImageTransform.Microsoft.Alpha(opacity=86) progid:DXImageTransform.Microsoft.gradient(startColorstr='#9A7FFF',endColorstr='#FE2AFF' , GradientType=1);
  }

.to-be-hidden-on-web-div-scroll.card,.to-be-shown-on-web-div-scroll{
  display: flex;
  opacity: 1;
  position: relative;
  transition: display 0.5s, opacity 0.5s linear;
}
.to-be-hidden-on-web-div-scroll.card.fadeOutUp.animated,.to-be-shown-on-web-div-scroll.animated.fadeOutUp{
  display: none;
  opacity: 0;
}
.to-be-hidden-on-web-div-scroll.card.fadeInUp.animated,.to-be-shown-on-web-div-scroll.animated.fadeInUp{
  display: flex;
  opacity: 1;
}

.lp-dynamic-container{
  max-height: 43px;
}
p img.resizable{
  float: left;
  padding-right: 15px;
}
.blog-data p img.resizable{
  float: none;
}
.trainer-data-div p{
  clear: both;
  padding: 10px 0px 10px 0px;
  display: flex;
    align-items: center;
}
.home_text_test_img {
  min-width: 128px;
  position: absolute;
  top: -4rem;
  left: 6rem;
  max-width: 128px;
}
.scrum-alliance {
  position: relative;
    top: 8px;
    left: 2px;
    height: 30px;
}
.testimonial-div-hidden{
  display: none;
}
.video-div-hidden{
  display: none;
}
.mt-7 {
  margin-top: 7rem !important;
}
.header-maximum-height{
  max-height: 43px;
  padding: 10px 10px;
  white-space: nowrap;
}
.header-vertical-center{
  display: flex;
  align-items: center;
  max-height: 43px
}
.m-b-1 {
  margin-bottom: 1rem;
}
.mar-bt {
  margin-bottom: 1rem !important;
}
.video_margin {
  margin-top: 30px;
  margin-bottom: 10rem;
}
.m-l-4 {
  margin-left: 4rem;
}
.m-t-5 {
  margin-top: 5rem;
}
.vid_btn:hover {
  text-decoration: underline;
}
.video_carousel .control-dots {
  position: relative;
  bottom: 4rem;
  margin: 0 !important;
  width: 100%;
}
@media (min-width: 960px) {
  .video_carousel .control-dots {
    bottom: 4rem;
  }
  .mobile-p-0{
    padding: 0px;
  }
}
#nav-tabContent .tab-pane {
  padding: 0rem !important;
}
.car_dots .control-dots {
  position: relative;
  bottom: 3rem;
  padding-left: 25px;
  width: 100%;
}
.course-videos-scroll-arrow{
  top: 3.5rem;
  position: absolute;
  font-size: 3rem;
  z-index: 2;
  padding: 0rem 1rem;
}

.course-arrow-top-position{
  top: 12.5rem;
}

.course-align-arrow-right{
  right: 0vw;
}

.course-desktop-inline-flex{
  display: flex;
  align-items: flex-start;
  overflow-x: scroll;
  overflow-y: hidden;
  /*min-height: 100%;*/
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  padding: 0rem 2rem;
}

.course-desktop-inline-flex::-webkit-scrollbar {
  display: none;
}

.course-video-modal .modal-dialog{
  max-width: 100%;
  height: 100%;
  margin: 0px;
}

.course-video-modal .modal-content{
  height: 100%;
  border: none;
  color: white;
  background: transparent;
}

.course-video-modal-closebutton{
  font-size: 30px;
  position: absolute;
  right: 15px;
  color: white;
  top: 15px;
  z-index: 2;
}

.course-video-thumbnail-playbutton{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  cursor: pointer;
  user-select: none;
  position: absolute;
}

.course-overlay-playbutton{
  color: white;
  margin-top: 16%;
  font-size: 4em;
}

.course-video-thumbnail{
  height: 100%;
  width: 100%;
  border-radius: 25px;
  border: 1px solid;
}

.city-testimonial-fontawesome-arrow {
  margin: 15rem 5px;
  background: -webkit-linear-gradient(left, #00d5ff ,#0072ff);
  width: 60px;
  height: 60px;
  padding: 14px;
  -webkit-border-radius: 50%;
  color: white;
}

.city-review-flex{
  height: 50vh;
  display: flex;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 50px;
  min-height: 580px !important;
  scroll-behavior: smooth;
}

.city-testimonial-div{
  background: linear-gradient(#fff ,#d5f5ff );
  border-radius: 15px;
  min-height: 335px;
  min-width: 300px;
  max-width: 315px;
  width: 300px !important;
  height: 60%;
}

.course-video-modal{
  background-color: rgba(0, 0, 0, 0.75);
}

.course-mobile-inline-flex{
  display: flex;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
  min-height: 28vh;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.course-mobile-inline-flex::-webkit-scrollbar {
  display: none;
}
.course-mobile-google-reviews{
  background-color: #d5f5ff;
  border-radius: 25px;
  min-height: 270px;
  max-height: 270px;
  overflow: scroll;
  display: inline-flex;
}
.overlay_single_video {
  width: auto;
  height: 100%;
  top: 0;
  left: 14px;
  right: 14px;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 1;
  cursor: pointer;
  user-select: none;
  position: absolute;
  border-radius: 15px;
}
.align-item-center{
  display: flex;
    align-items: center;
}
.notify-button{
  text-align: left;
    border-radius: 25px;
    padding: 0.5rem 1rem;
}
.pad_test {
  padding: 0 1rem;
}
.video_drop_button {
  position: absolute;
  right: 0;
}
.resources-flex, .services-flex {
  display: flex;
  align-items: center;
  overflow-y: hidden;
  overflow-x: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
.resources-flex::-webkit-scrollbar {
  display: none;
}
.services-flex::-webkit-scrollbar {
  display: none;
}
.resource-card, .video-card, .service-card {
  /*background: url('assets/img/cities/bengaluru.jpg');*/
  /*background-color: #1f989e;*/
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25px;
  /*position: relative;*/
  min-height: 235px;
  min-width: 235px;
  margin: 0rem 1rem;
  transition-duration: .3s;
}

.resource-img, .service-img{
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  user-select: none;
  position: absolute;
  border-radius: 25px;
}

.resource-card-overlay, .service-card-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.4);
  z-index: 1;
  cursor: pointer;
  user-select: none;
  position: absolute;
  border-radius: 25px;
}

.align-text-bottom {
  display: flex;
  height: 100%;
}

.align-heading-bottom {
  display: inline-block;
  align-self: flex-end;
}

.text-bottom-zero{
  position: absolute;
  bottom: 0;
  z-index: 1;
}

.resource-content {
  background: #f7f8f9;
  border-radius: 25px 25px 0px 0px;
}
.resource-page-register {
  background: -webkit-linear-gradient(left,#00d5ff,#0072ff);
  color: white;
  border-radius: 25px;
  font-size: 1.5rem;
  border: none;
}
.selected-resource, .selected-service {
  transform: scale(1.2);
  transition-duration: .3s;
  margin: 0rem 2.5rem;
}
.selected-content {
  display: block;
}
.btn_dropdown .btn-secondary {
  background-color: #f7f8f9;
  border-color: transparent;
}
.btn_dropdown_menu .dropdown-menu {
  left: auto !important;
  right: 0;
  min-width: 11rem;
}
.dropdown-menu.training-dropdown-menu {
  padding: 10px;
  min-width: 100%;
  right: unset;
}
.resource-page-list {
  list-style: none;
}
.resource-video-playbutton {
  top: 37%;
  left: 42%;
  right: 0;
  bottom: 0;
  cursor: pointer;
  user-select: none;
  position: absolute;
  font-size: 4rem;
  color: white;
}
.green-tick {
  font-size: 1rem;
  color: white;
  background-color: green;
  border-radius: 50%;
  padding: .2rem;
  margin-right: 1rem;
}
.nav_active .nav-link.active {
  margin-top: 0;
  border-top: none;
  border-bottom: 2px solid #00000082;
}
.nav_button button:focus {
  outline: none;
}
.main-heading-select.active{
  color: #00c0ff;
}
.online-trainings{
  display: none;
}
.classroom-trainings{
  display: none;
}
.inline-flex::-webkit-scrollbar {
    display: none;
}
.csm-study.active::after{
  font-size: 1.5rem;
  position: absolute;
    bottom: 5px;
    color: #ffffff;
    left: 44%;
  content: "\E607";
  font-family: 'simple-line-icons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.video_wrapper .slider-wrapper {
  margin: -2rem 0 0 0;
}
.nav_link .nav-link {
  border: none;
  background-color: #f7f8f9;
}
.about_us_Satisha {
  text-align: center;
  padding: 0 6rem;
  order: 2;
  margin-top: 10rem;
}
.about_us_Hema {
  padding: 0 2rem;
}
.about_us_Apeksha {
  padding-left: calc(100% * 2/12 - 9rem);
  padding-right: 12rem;
  margin-top: 8rem;
}
.about_us_Jayaprakash {
  padding-left: 3rem;
  padding-right: 3rem;
  text-align: center;
  margin-top: 8rem;
}
.about_us_Gera {
  padding: 0 3rem;
}
.about_us_Vivek {
  text-align: center;
  padding: 0 5rem;
  margin-top: 2rem;
}
.about_us_Mohit {
  padding-left: 6rem;
  padding-right: 5rem;
}
.about_us_Abhishek {
  padding: 0 3rem;
  margin-top: 2rem;
}
.about_us_Anusha {
  padding-left: 6rem;
  margin-top: 7rem;
}
.about_us_Shashank {
  padding: 0 6rem;
}
.about_us_Bagya {
  margin: 8rem 0 0;
  padding-left: 4rem;
  padding-right: 9rem;
}
.about_us_Somaji {
  margin-top: 9rem;
  padding: 0 3rem;
}
.about_us_Pravat {
  margin-top: 2rem;
  padding: 0 3rem;
}
.about_us_Harry {
  padding: 0 7rem;
  margin-top: 10rem;
}
.about_us_Vignesh {
  padding: 0 5rem;
}
.about_us_Chota {
  padding: 0 3rem;
  margin-top: 8rem;
}
.about_us_Naveen {
  padding: 0 7rem;
  margin-top: 8rem;
}
.about_us_Amit {
  padding: 0 5rem;
}
.about_us_image {
  position: relative;
  overflow: hidden;
}
.about_us_image img {
  max-width: 100%;
  -moz-transition: all 1s;
  -webkit-transition: all 1s;
  transition: all 1s;
}
.about_us_image:hover img {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.about_us_image:hover .about_us_overlay {
  display: block;
}
.about_us_image:hover .about_us_div {
  display: block;
}
.about_us_display {
  display: flex;
  margin-top: 4rem;
}
.about_us_overlay {
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
}
.about_us_div {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}
.about_us_btn {
  background-color: #000;
  color: #fff;
  border-radius: 15px 15px;
}
.about_us_pf_btn {
  padding: 5px 7px;
  border-radius: 50%;
}
.about_us_watch_btn {
  border: 1px solid;
  margin-bottom: 0.5rem;
}
.about_us_top {
  // background-color: #a6ddf594;
  background-color: #f7f8f9;
}
.about_us_mob_top {
  background-color: #f7f8f9;
}
.about_us_top iframe{
  // width: 58%;
  padding: 2rem 0 0 2rem;
}
.about_us_card{
  box-shadow: 1px 1px 15px 0.5px #70809085;
}
.video_pad {
  padding: 0 2rem;
}
.about_us_section {
  display: flex;
  padding-bottom: 10rem;
}
.video_btn {
  text-align: center;
  cursor: default;
}
.video_overlay {
  left: 31px;
  right: 31px;
  cursor: default;
}

.resource-inline-flex, .service-inline-flex {
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 60px;
}

.mobile-resource-card:nth-child(odd), .mobile-service-card:nth-child(odd) {
  float: left;
}

.mobile-resource-card:nth-child(even), .mobile-service-card:nth-child(even) {
  float: right;
}

.mobile-resource-card:nth-child(2), .mobile-service-card:nth-child(2) {
  margin-top: 70px;
}
.resource-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.6);
  user-select: none;
  position: absolute;
}

.resource-back-arrow {
  color: white;
  font-size: 2rem;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 968px) {
  .mobile-p-0 {
    padding: 0px !important;
  }
}
.refer-gift .fa-check-circle.check-icon{
  display: none;
}
.refer-gift.active .fa-check-circle.check-icon{
  display: block;
}

.resource-scroll-arrow, .service-scroll-arrow {
  z-index: 2;
  top: 235px;
  font-size: 4rem;
  position: absolute;
  color: white;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  width: 4rem;
}

.resource-arrow-right, .service-arrow-right {
  right: 0px;
}

.resource-scroll-arrow:hover, .service-scroll-arrow:hover {
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.fixed-resource-header {
  position: fixed;
  top: 115px;
  left: 24px;
  padding-left: 1.85rem;
  width: 100%;
  background-color: #f7f8f9;
}

.fixed-resource-header + .resource-description {
  padding-top: 35px;
}

.hidden-resource-header {
  z-index: 2;
}
.badge-interest:hover{
  background-color: #c8ced3;
}

.resource-boxshadow, .service-boxshadow {
  border: 2px solid;
  box-shadow: 0px 1px 5px;
}

.mobile-resource-description span {
  color: white !important;
}

.resource-image-modal .modal-content {
  align-items: center;
}

.resource-image-modal {
  text-align: center;
  padding: 0!important;
}

.resource-image-modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.resource-image-modal .modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.template-resource-image {
  width: 100%;
  border-radius: 25px;
  box-shadow: 0px 1px 5px;
  border: 1px solid;
  /*max-height: 300px;*/
  height: 85%;
  object-fit: cover;
}

.mobile-template-resource-image {
  width: 100%;
  border-radius: 25px;
  box-shadow: 0px 1px 5px;
  border: 1px solid;
  max-height: 232px;
  height: 90%;
  object-fit: cover;
}

.resource-modal-image{
  max-height: 90vh;
  max-width: 90vw;
}

.mobile-resource-modal-image{
  max-height: 80vh;
  max-width: 90vw;
}

.resource-template-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  /*z-index: 1;*/
  cursor: pointer;
  user-select: none;
  position: absolute;
}

.template-download-overlay {
  top: 25%;
  text-align: center;
  position: absolute;
}

.resource-download-arrow {
  font-size: 2.5rem;
}

.resource-download-button {
  border-radius: 25px;
  padding: 0rem 1.5rem;
  /*margin: 0rem 1rem;*/
  color: white;
  border: 1px solid white;
}

.course-card-vertical-scroll {
  overflow-y: scroll;
  height: 100%;
}

.image-shadow {
    box-shadow: 0px 1px 5px;
    border: 1px solid;
}
.trainer_testimonial{
  // max-width: 300px;
  max-width: 330px;
  // height: 65%;
  height: 63%;
  margin-right: 3rem;
  margin-bottom: 7rem;
}
.trainer_test_arrow{
  position: absolute;
  bottom: 2rem;
}

.dt-resource-bg {
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 225px;
}
.course_test_arw {
  top: 16rem;
  position: absolute;
  font-size: 3rem;
  z-index: 2;
  padding: 0rem 1rem;
}
.course_arw_index {
  z-index: 3;
}
.op-1 {
  opacity: 1;
}

.service-section-button {
  border-radius: 25px;
  color: white;
  border: none;
  font-size: 1rem;
  background : -webkit-gradient(linear,0% 50% ,100% 50% ,color-stop(0,rgba(0, 191, 212, 1) ),color-stop(1,rgba(21, 75, 255, 1) ));
}

.resource-imagemodal-close-button {
  position: absolute;
  top: -30px;
  right: 0px;
  font-size: 2rem;
  cursor: pointer;
  color: white;
}

.white-text.workshop-man {
  a {
    color: #1fcde8;
    text-decoration: underline;
  }
  h1,h2,h4{
    color: #FFFFFF !important;
  }
  h2{
    color: #FFFFFF !important;
  }
}
.styles_modal__gNwvD.modal-login{
  @media screen and (max-width: 1274px){
    .float-left.text-center.p-5 {
      padding-left: 0px !important;
      padding-right: 0px !important;
      margin-left: 30px;
      margin-right: 0px;
    }
    .bg-blue-login{
      display: none;
    }
    .col-10.center.referal-form.position-relative.row.form-group{
      padding: 0px;
      margin: 0px;
      max-width: 100%;
    }
    button.float-left.btn.btn-pill.m-l-10.rounded-bordered.margin-top-20.btn.btn-primary {
      margin-bottom: 10px;
    }
  }
}
.angle-position {
  margin-top: -2rem;
  padding-bottom: 2rem;
  z-index: 2;
  @media (max-width: 980px) {
    padding-bottom: 0px;
  }
}
.modal.payment-modal.fade.show .modal-dialog {
  @media (max-width: 576px) {
    margin: 0;
  }
  @media (min-width: 576px){
    margin: 3rem auto;
  }
  @media (min-width: 980px){
    max-width: 80vw !important;
  }
}
.modal.payment-modal.fade.show {
  @media (max-width: 576px) {
    background: white;
  }
}
.modal.payment-modal.fade.show .modal-content {
  background: transparent;
  border: 0px;
  @media (max-width: 576px) {
    padding-bottom: 75px;
    min-height: 100vh;
  }

  @media screen and (max-width: 600px){
    i.fa.fa-times-circle-o {
      position: absolute;
      z-index: 1000;
      right: 4px;
      color: #FFFFFF;
      font-size: 40px;
      top: 5px;
      /*margin-right: -62px;*/
    }
  }
  @media screen and (min-width: 600px){
    i.fa.fa-times-circle-o {
      position: absolute;
      z-index: 1000;
      right: -17px;
      color: #FFFFFF;
      font-size: 40px;
      top: -14px;
      /*margin-right: -62px;*/
    }
  }
  .mobile-pay-button {
    background: #22b573;
    @media (max-width: 576px) {
      position: fixed;
      bottom: 0;
      border-radius: 45% 44% 0% 0%;
      background: linear-gradient(90deg, #00e97e 0%, #00d9e9 100%);
    }
  }
  .mobile-quantity-section{
    position: absolute;
    bottom: -27px;
    z-index: 2;
  }
  .mobile-benefits-arrow{
    position: absolute;
    right: 21px;
    font-size: 2rem;
    top: -4px;
    background: #A5A5A5;
    height: 32px;
    width: 32px;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0px 0px 7px 0px;
  }
  .mobile-detail-button {
    color: white;
    font-size: 1rem;
    border: 1px solid white;
    background: transparent;
    border-radius: 15px;
    padding: 2px 10px;
  }
  .flex-display{
    display: flex;
  }
  .alert-padding{
    padding: 5px
  }
  .flex-center-aligned {
    display: flex;
    align-items: center;
  }
  .flex-centered{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .collapse-card.p-2.card{
    position: relative;
    z-index: 0;
  }
  .payment-free-image{
    width: 65px;
    border-radius: .5rem;
  }
  .apply-button {
    margin: 5px 15px 5px 15px;
    max-width: 64px;
    max-height: 25px;
  }
  .section-border-bottom{
    border-bottom: 1px solid #DCDCDC;
  }
  /*.modal-row .col-12{
    padding-left: 15px;
  }*/
  .price-container{
    padding: 12px 0px;
  }
  .basic-price,.discount-value{
    border-bottom: 1px solid #DCDCDC;
  }
  button.payment-button.col-12.btn.btn-secondary {
    margin-left: auto;
    margin-right: auto;
    max-width: 139px;
    font-size: 26px;
    color: #FFFFFF;
    background: #21b573;
    border-radius: 30px;
    padding-left: 0px !important;
  }
  .basic-price{
    color: #999999;
    .text-right{
      font-size: 28px;
    }
    i.fa.fa-caret-down{
      position: absolute;
      top: 14px;
    }
    h4{
      line-height: 33px;
    }
    @media screen and (max-width: 600px){
      i.fa.fa-caret-down{
        right: 10px;
      }
      h4{
        font-size: 15px;
        padding: 0px;
      }
      .text-right{
        font-size: 20px;
      }
    }
  }
  .discount-value{
    color: #28abe2;
    .text-right{
      font-size: 28px;
    }
    h4{
      line-height: 33px;
    }
    @media screen and (max-width: 600px){
      h4{
        font-size: 15px;
        padding: 0px;
      }
      .text-right{
        font-size: 20px;
      }
    }
  }
  .final-price{
    color: #21b573;
    .text-right{
      font-size: 28px;
    }
    h4{
      line-height: 33px;
    }
    @media screen and (max-width: 600px){
      h4{
        font-size: 15px;
        padding: 0px;
      }
      .text-right{
        font-size: 20px;
      }
    }
  }
}
@media screen and (min-width: 500px){
  iframe.razorpay-checkout-frame {
    min-width: calc(100vw);
    min-height: calc(100vh);
  }
}
@media screen and (max-width: 500px){
  iframe.razorpay-checkout-frame {
    min-width: calc(100vw);
    max-height: calc(100vh - 50px);
  }
}
.ticket-modal{
  margin-top: 20px;
  background: #FFFFFF;
  min-height: 200px;
  min-width: 550px;
  margin-left: -44px;
  position: relative;
  z-index: -1;
  border-radius: 10px;
  top: -116px;
  padding-top: 126px;
  color: #73818f;
  i.fa.fa-inr {
    margin-right: 10px;
    font-size: 33px;
  }
  i.fa.fa-map-marker {
    font-size: 24px;
  }
  @media screen and (min-width: 600px){
    .col-12.price-main-container.row{
      min-width: 563px;
    }
  }
  @media screen and (max-width: 600px){
    .col-12.price-main-container.row{
      min-width: calc(100vw);
      max-width: calc(100vw);
    }
  }
  .col-1.btn.white-text:hover {
    color: #FFFFFF;
  }
}
@media screen and (max-width: 600px){
  .ticket-modal{
    min-width: calc(100vw);
    margin-left: -24px;
    margin-right: auto;
    .col-1.btn.white-text{
      color: #FFFFFF !important;
    }
  }
}
.case-study, .video-section, .soundcloud-section{
  border-bottom: 1px solid gray;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}
.case-study-image {
  width: 100%;
  border-radius: 25px;
  box-shadow: 0px 1px 5px;
  border: 1px solid;
  max-height: 260px;
  height: 85%;
}
.logo-inner-div:focus,.navbar-brand-full:focus,.fa-arrow-left:focus,.fa-angle-left:focus,.fa-arrow-right:focus,.fa-angle-right:focus,.navbar-brand:focus {
  outline: none;
}
.course-left-section {
  padding: 0rem 4rem 0rem 1rem;
}
.blog-data iframe {
  height: 495px;
}
.blog-data img {
  height: 450px;
}
#select-city-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  top: 0;
  left: 0;
}
.overlay-test {
  width: 3%;
  height: 15%;
  top: 40%;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
}
.overlay-trainer-video {
  width: 6%;
  height: 60%;
  top: 10%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
}
.allcity-city-button:hover {
  color: #20a8d8;
}
.faq-question {
  font-size: 1.2rem;
  margin: -25px 0px 0px 19px;
}
.allcity-mobile-faq-section h3 {
  margin: 0 !important;
}


a.participant-detail-btn.hall-pass-button {
  color: #FFFFFF;
  right: 177px;
}
a.participant-detail-btn.hall-pass-button.new {
  color: #FFFFFF;
  right: 65px;
}
.participant-detail-btn.status-button {
  color: #FFFFFF;
  @media (max-width: 980px)  {
    font-size: 11px;
  }
}
.b-r-defa{
  border-radius: 0px 0px 15px 15px;
}
.min-max-h-80{
  min-height: 80px;
    max-height: 80px;
}
.participant-invite-card.col.bg-green-invite{
  background: #047e04;
}
select.lp-select {
  padding: 10px;
  -webkit-appearance: inherit;
}
select.lp-select {
  max-width: 100%;
}
@media screen and (min-width: 600px){
  select.lp-select {
    min-width: 200px;
  }
}
@media screen and (max-width: 600px){
  select.lp-select {
    min-width: 100%;
    margin-bottom: 10px;
  }
  footer.lp-empty-blue-grad.app-footer.app-footer {
    z-index: 1;
  }
}
.pt-7{
  padding-top: 7rem;
}
.disflex {
  display: inline-flex;
}
.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: none;
}
.overx {
  overflow-x: hidden;
}
.case-study a {
  color: blue;
}
.video-section a {
  color: blue;
}
.resource-mobile-video-section a {
  color: blue;
}
.cookieConsent{
  width: 90% !important;
  z-index: 10005 !important;
}
@media only screen and (max-width: 968px) {
  .cookieConsent{
    width: 100% !important;
  }
}
button.btn-pill.btn.btn-primary.btn-block {
  min-width: 60px;
}
.black-box-shadow{
  box-shadow: 0 2px 9px 0px #000000;
  z-index: 2;
}
.modal-video-movie-wrap iframe {
  width: 100% !important;
  height: 100% !important;
}
.tdetail {
  @media (max-width: 1285px) {
    padding-left: 2.5rem;
  }
}
.green{
  color: green;
}
.b-g-grey{
  background: #efefef;
}
.font-16{
  font-size: 16px !important;
}
.font-25{
  font-size: 25px !important;
}

.case-study-categories-fixed{
  position: fixed;
  top: 180px;
  left: 24px;
  padding-left: 15px !important;
  width: 100%;
  background-color: #f7f8f9;
  padding-right: 13px;
}

.case-study-to-hide-left-margin{
  margin-left: 240px;
  margin-top: -10px;
}

.case-study-scroll{
  margin-left: 240px;
  overflow: hidden;
}

.case-study-categories-scroll {
  min-height: 60vh;
  padding-left: 0px;
  overflow: scroll;
}

.left-curved-serach-button {
  border-radius: 20px 0px 0px 20px;
}
.right-curved-input {
  border-radius: 0px 20px 20px 0px;
  border: 1px solid skyblue;
  font-size: 1rem;
}
.product-nav-link {
  font-size: 1.5rem;
  border-radius: 15px 15px 0px 0px !important;
  padding: .2rem 2rem;
  @media (max-width: 980px) {
    font-size: 1.1rem;
    border-radius: 25px 25px 0px 0px !important;
  }
}
.modal-carousel-img img {
  width: 100% !important;
  height: 100% !important;
  margin-top: 30%;
}
.modal-carousel-img li {
  background-color: black;
}
.modal-carousel-img .carousel-indicators {
  bottom: 0;
}
.products-section {
  background-color: white;
  min-height: 35rem;
  border-radius: 25px;
  box-shadow: 0px 0px 10px -5px;
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 980px) {
    border-radius: 0px 25px 25px 25px;
    padding: 0rem 0rem;
   /* margin-bottom: 3rem;
    overflow-y: scroll;
    max-height: 78vh;
    min-height: 78vh;
    padding-bottom: 3rem;
*/  }
  /*@media (min-height: 812px) and (max-height: 824px) {
    max-height: 81vh;
    min-height: 81vh;
  }*/
}
/*.mobile-products {
  position: fixed;
  left: 16px;
  right: 16px;
}*/
.products-nav-item-active {
  box-shadow: 0px -11px 11px -14px;
  z-index: 1;
  border-radius: 15px 15px 0px 0px;
  @media (max-width: 980px) {
    border-radius: 25px 25px 0px 0px;
  }
}
.products-nav-tab .nav-tabs {
  border: none !important;
}
.mobile-products {
  transition: all 2s linear;
}
.product-section-to-hide{
  margin-top: -100px !important;
  transition: all 300ms linear;
}

.offer-p-tag{
  max-width: none;
  left: 16%;

  @media only screen and (max-width: 1200px) {
    display: none;
  }
}
@media only screen and (max-width: 1269px  ){
  .offer-p-tag{
    max-width: 300px;
    left: 19%;
    top: 2px !important;
    font-size: small;
  }
}

.video_playlist {
  @media (max-width: 992px) {
    margin-top: 1rem;
  }
}

.all-product-card {
  color: white;
  border-radius: 15px;
  float: left;
  padding: 1rem 1rem 0rem 1rem;
  max-width: 14rem;
  max-height: 14rem;
  min-height: 14rem;
  min-width: 14rem;
  @media (max-width: 980px) {
    min-width: 11rem;
    max-width: 12rem;
    max-height: 12rem;
    min-height: 12rem;
  }
  @media (max-width: 360px) {
    min-width: 10rem;
  }
}
.product-card-down-arrow {
  font-size: 2rem;
}
#product-cart-button {
  padding: .2rem 1rem;
  position: absolute;
  right: 50px;
  top: 45px;
  border-radius: 20px;
  font-size: 1.2rem;
  z-index: 1;
  background-color: white;
  border: 1px solid lightgray;
  box-shadow: 0px 0px 3px -1px;
  @media (max-width: 980px) {
    background: white;
    font-size: 1.1rem;
    right: 12px;
    top: -39px;
  }
}
#product-cart-orders-button {
  padding: .2rem 1rem;
  position: absolute;
  right: 195px;
  top: 45px;
  border-radius: 20px;
  font-size: 1.2rem;
  z-index: 1;
  background-color: white;
  border: 1px solid lightgray;
  box-shadow: 0px 0px 3px -1px;
  @media (max-width: 980px) {
    background: white;
    font-size: 1.1rem;
    right: 22px;
    top: -39px;
  }
}
#product-cart-button:focus {
  outline: none;
}
.product-down-arrow {
  position: absolute;
  bottom: 0px;
  left: 0px;
}
.merchandise-name {
  position: absolute;
  bottom: 35px;
  left: 0px;
}
.merchandise-image {
  width: 100%;
  /*height: 87px;*/
  @media (max-width: 980px) {
  /*height: 55px;*/
  }
}
.single-merchandise-section {
  margin-bottom: 3rem;
  max-height: 14rem;
  min-width: 15rem;
  display: flex;
  justify-content: center;
  @media (max-width: 980px) {
    min-width: 10rem;
    max-height: 12rem;
    margin: 1rem 0rem 0rem 0rem;
  }
}
.single-merchandise-section:nth-child(2n) {
  @media (max-width: 767px) {
    margin-top: 2rem;
  }
}
.single-product-section {
  margin-bottom: 3rem;
  max-height: 14rem;
  min-width: 15rem;
  display: flex;
  justify-content: center;
  @media (max-width: 980px) {
    min-width: 10rem;
    max-height: 17rem;
    margin: 1rem 0rem 2rem 0rem !important;
  }
}
.part-div{
  max-width: 163px;
  text-align: center;
  margin: auto;
  font-size: 14px;
  margin-bottom: 20px;
}
.all-products-container {
  background-color: royalblue;
  border-radius: 20px;
  padding: 1.5rem;
  min-height: 31rem;
  @media (max-width: 980px) {
    padding: 2rem 0rem;
    border-radius: 0rem;
  }
}
.single-product-container {
  background-color: royalblue;
  border-radius: 20px;
  padding: 1.5rem;
  min-height: 31rem;
  @media (max-width: 980px) {
    border-radius: 0rem;
    padding: 0rem;
  }
}
.products-section-header {
  color: white;
}
.product-card {
  color: white;
  height: 18rem;
  width: 25%;
  max-width: 18rem;
  min-width: 17rem;
  padding: 0rem 2rem 0rem 2rem;
  margin-bottom: 3rem;
  position: relative;
  @media (max-width: 980px) {
    padding: 1px;
    min-width: 10rem;
    max-width: 12rem;
    height: 15rem;
    margin-bottom: 0rem;
  }
}
.product-card-image {
  height: 13rem;
  @media (max-width: 980px) {
    height: 10rem;
  }
}
.all-product-cards {
  display: flex;
  flex-wrap: wrap;
  min-height: 26rem;
  padding: 1.5rem;
  @media (max-width: 980px) {
    padding: 0rem;
  }
}
.product-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  user-select: none;
  position: absolute;
  opacity: 0;
  transition: .5s ease;
}
.product-card:hover .product-overlay {
  opacity: 1;
}
.product-overlay-button {
  width: 52%;
  border-radius: 10px;
  cursor: pointer;
  margin-top: .5rem;
  cursor: pointer;
}
.product-overlay-fixed-text {
  position: absolute;
  top: 40px;
}
.product-share-button {
  font-size: 1.4rem;
  position: absolute;
  top: 9px;
  right: 11px;
  cursor: pointer;
}
.image-focus {
  border-color: #e77600;
    box-shadow: 0 0 3px 2px rgba(228,121,17,.5);
}
.color-square {
    width: 2rem;
    height: 2rem;
    padding: 0px;
    margin-right: 1rem;
    float: left;
    cursor: pointer;
    border: 1px solid black;
}
.all-product-cards p {
  margin-bottom: .0rem !important;
}
.add-to-cart-section {
  background: white;
  width: 100%;
  height: 17rem;
  float: left;
  border-radius: 15px;
  box-shadow: 0 0 12px -1px black;
  padding: 1rem;
  min-width: 240px;
}
.product-no-of-items > * {
  display: inline-block;
}
.product-no-of-items {
  border-bottom: 1.5px solid gray;
}
.add-to-cart-section input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.add-to-cart-section input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.add-to-cart-section input[type="number"] {
  -moz-appearance: textfield;
}
#product-add-to-cart-count {
  border-radius: 50%;
  text-align: center;
  padding: 0;
}
.cart-button {
  font-size: 1.2rem;
  cursor: pointer;
  background: royalblue;
  color: white;
  border-radius: 15px;
  padding: .3rem;
  width: 65%;
  min-width: 150px;
}
.scrollable-product-detail {
  display: flex;
  flex-wrap: wrap;
  min-height: 16rem;
}
.scrollable-product-detail::-webkit-scrollbar {
    display: none;
  }
.shopping-cart, .user-orders {
  background-color: white;
  min-height: 35rem;
  border-radius: 25px 25px;
  box-shadow: 0px 0px 10px -5px;
  padding: 2rem 0rem 0rem 0rem;
  @media (max-width: 980px) {
    padding: .5rem 0rem 0rem 0rem;
  }
}
.shopping-cart-top {
  @media (min-width: 767px) and (max-width: 980px) {
    top: 7% !important;
  }
}
.cart-product-row > * {
  display: inline-block;
}
.cart-product-row {
  padding: 1rem 0rem;
  border-bottom: 1px solid gray;
}
.cart-edit-icon {
  font-size: 1.5rem;
  text-align: center;
}
.cart-footer {
  position: absolute;
  bottom: 0px;
  /*font-size: 2rem;
  left: 0px;*/
}
/*.cart-footer > * {
  display: inline-block;
}
.cart-footer-pay-section > * {
  display: inline-block;
}*/
.cart-footer-pay-section {
  text-align: center;
  cursor: pointer;
  background-color: #20a8d8;
  color: white;
}
#cart-payment-button{
  font-size: 1.5rem;
  background: transparent;
  border-radius: 20px;
  padding: 0rem 2rem;
  color: white;
}
.cart-image {
  height: 12rem;
  width: 12rem;
  border-radius: 15px;
}
.cart-products{
  display: flex;
  flex-wrap: wrap;
  min-height: 26rem;
  padding-bottom: 3rem;
}
.app-header .navbar-brand {
  @media (max-width: 992px) {
    top: 2.5rem;
    left: 57%;
  }
}
.blue-curve button.abs, .blue-curve {
  @media (max-width: 992px) {
    display: block !important;
    right: 0;
    max-width: 63px;
  }
}
.fixed-div-form{
  position: fixed;
    top: 100px;
    z-index: 10006;
    background: #ffffff;
    display: none;
}
.fixed-div-form.fadeOutNo.animated{
  display: none;
}
.fixed-div-form.fadeInNo.animated{
  display: flex;
}
.fixed-course-div{
  position: fixed;

  display: block;
  z-index: 996;
  @media (max-width: 992px) {
    margin-top: 6rem;
  }
}
.course-right-div{
  padding-left: 50px;
    padding-right: 30px;

    @media (max-width: 992px) {
      margin-top: 6rem;
    }
}
.font-4-and-6{
  font-size: 6rem;
}
@media (max-width: 1297px) {
    .font-4-and-6{
      font-size: 4rem;
    }
}
@media (max-width: 1128px) {
    .course-left-section{
      padding: 0rem 0rem 0rem 1rem;
    }
}
/*@media (max-width: 991px) {
    .mt-4{
      margin-top: 0rem !important;
    }
}*/
.logo-div {
  @media only screen and (max-width: 992px) {
    max-width: 50px;
  }
}
.d-md-offer-none {
  @media (max-width: 1100px) {
    display: none;
  }
}
.claim-button.btn-secondary:hover{
  color: yellow;
}
.claim-button.btn-secondary:active{
  color: yellow !important;
}
.styles_modal__gNwvD.modal-quiz{
  width: 100%;
  max-width: inherit;
  height: 100vh;
  overflow: auto;
  padding: 0px;
}
 .quiz-options.active{
  background: #23abe1;
}
.quiz-options.active .option-text{
  color: #FFFFFF;
}
.question-div{
  /*min-height: 500px;*/
  width: auto;

}
.dis-tab-mid{
  display: table-cell;
    vertical-align: middle;
    height: inherit;
}
.quiz-options-image{
  background-size: contain;
  width: 190px;
  height: 190px;
  background-repeat: no-repeat;
}
.quiz-options-image-hover-effect{
  width: 180px;
  height: 200px;
}
.quiz-options-image-hover-effect:hover{
  background: #4dbd74;
}
.quiz-options-image-hover-effect.active{
  background: #4dbd74;
}
.displayimg {
  @media (min-width: 577px) and (max-width: 980px) {
    display: block !important;
  }
}
.header-fixed .app-header {
  @media (min-width: 992px) {
    z-index: 1000;
    top: 0;
  }
}
.cart-address-modal {
  top: 75px;
}
.cart-address-modal .modal-content {
  padding: 1rem;
}
#cart-continue-payment-button {
  margin-left: auto;
  margin-right: auto;
  font-size: 26px;
  color: #FFFFFF;
  background: #21b573;
  border-radius: 30px;
  padding: .3rem 3rem;
}
.cart-address-inline-section > * {
  display: inline-block;
}
.city-right-div{
  padding-left: 40px;
}
.startup-planner {
  border-left: 5px solid #01aeef;
}
.softwares-products {
  background-color: white;
  border-radius: 25px;
  box-shadow: 0px 0px 10px -5px;
  padding: 2rem;
  @media (max-width: 980px) {
    max-height: 75vh;
    min-height: 75vh;
    overflow-y: auto;
  }
}
.software-product-image {
  height: 350px;
  object-fit: contain;
  width: 100%;
}
.leangears-section {
  border-left: 5px solid black;
}
.software-know-more-button {
  border: 1px solid #01aeef;
  padding: .5rem 2rem;
  border-radius: 20px;
  color: #01aeef;
  @media (max-width: 980px) {
    background: #01aeef;
  }
}
.software-know-more-button.lean-gears {
  @media (max-width: 980px) {
    background: black;
    border: 1px solid black;
  }
}
.software-know-more-button a {
  color: #01aeef;
  @media (max-width: 980px) {
    color: white;
  }
}
.software-know-more-button a:hover {
  color: white;
}
.software-know-more-button:hover a {
  color: white;
}
.sitemapfont {
  font-family: "Open Sans",Helvetica, Arial, sans-serif;
}
.sfont {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
}
.sitehead {
  line-height: 40px;
}
.sitemapbg {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
  transition: .3s;
  padding: 8px 15px;
  margin-top: 1rem;
}
.sitecolor {
  position: relative;
  color: rgb(83, 83, 83);
}
@media (max-width: 980px) {
  .margleft {
    margin-left: 0px !important;
  }
  .margtop {
    margin-top: 1rem !important;
  }
  .margbtm {
    margin-bottom: 0px !important;
  }
  .mbtm {
    margin-bottom: 5rem !important
  }
  .quiz-options:hover{
    background: #f2f3f4;
  }
  .quiz-options:hover .option-text{
    color: #000000;
  }
  .quiz-options.active, .quiz-options.active:hover{
    background: #23abe1;
  }
  .quiz-options.active .option-text, .quiz-options.active:hover .option-text{
    color: #FFFFFF;
  }
  /*.styles_modal__gNwvD.modal-quiz{
    width: 100%;
    max-width: inherit;
    height: 100vh;
    overflow-y: auto;
    padding: 0px;
  }*/
  body.modal-open {
      overflow: hidden;
  }
  .styles_modal__gNwvD, body{
    -webkit-overflow-scrolling: touch;
  }
}
.styles_modal__gNwvD{
  -webkit-overflow-scrolling: touch;
  border-radius: 25px;
}
.get-started-button{
  background-color: rgb(37, 37, 37);
    color: rgb(228, 228, 228);
    padding: 8px 18px;
    border-radius: 4px;
    font-size: 20px;
}
.react-phone-number-input__input {
  border-bottom: none !important;
  background: transparent;
  color: white;
}
.react-phone-number-input__icon {
  min-width: 2.24em;
  min-height: 1.93em;
  border: none !important;
}
 input[type='phone']#brochure_mobile::placeholder, input[type='phone']#brochure_mobile {
  color: #ccc;
}
input[type='phone']#brochure_mobile:focus {
  color: #5c6873;
}
.background_offer {
	// background: linear-gradient(#c7db64 40%, #93c843 60%);
	// background: linear-gradient(90deg, #4c0b58 2.71%, #fe2aff 100%);
	// background: linear-gradient(-220deg, rgb(255, 165, 0), rgb(255, 255, 255), rgb(0, 128, 0));
	border-radius: 10px;
	color: #FFF;
	// color: #000;
  background: linear-gradient(to right, rgb(192, 36, 37), rgb(240, 203, 53));
}
.disabled{
  pointer-events: none;
  opacity: 0.4;
    cursor: none;
}
.current.disabled{
  pointer-events: all;
  opacity: 1;
    cursor: pointer;
}
.react-phone-number-input.enquiry_form_control {
    display: block;
    width: 88%;
    height: calc(2.0625rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5c6873;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e4e7ea;
    border-radius: 0.25rem;
    position: relative;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.input-group > .enquiry_form_control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.backbgbutton, .btn-secondary.backbgbutton:hover {
  background: linear-gradient(to right, #0a334fc7, #02173de8);
}
.dropdown-item.dbtn:hover, .dropdown-item.dbtn:focus, .dropdown-item.dbtn:active {
  background-color: transparent;
}
.disoffer {
  @media (min-width: 1200px) and (max-width: 1265px){
    max-width: 400px;
    font-size: small;
    top: 2px !important;
    left: 16%
  }
}
.offer_display {
  @media (min-width: 1030px) and (max-width: 1200px) {
    left: 19%;
    position: absolute;
    padding: 7px 10px 7px 10px;
    top: 10px;
    display: block !important;
  }

  @media (min-width: 980px) and (max-width: 1030px) {
    left: 19%;
    position: absolute;
    padding: 7px 10px 7px 10px;
    top: 10px;
    display: block !important;
    font-size: smaller;
  }
}
.offer_div:hover .offer_display{
  @media (min-width: 980px) and (max-width: 1200px) {
    display: none !important;
  }
}
.offer_div:hover .hover_offer_display{
  @media (min-width: 1030px) and (max-width: 1200px) {
    top: 2px !important;
    display: block;
    min-width: 300px;
    font-size: small;
    left: 19%;
  }

  @media (min-width: 980px) and (max-width: 1029.9px) {
    top: 2px !important;
    display: block;
    max-width: 250px;
    font-size: small;
    left: 19%;
  }
}
:not(output):-moz-ui-invalid {
  box-shadow: none;
}
.cart-count-field{
  border-radius: 50%;
  text-align: center;
}
.cart-product-row input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.cart-product-row input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.cart-product-row input[type="number"] {
  -moz-appearance: textfield;
}
.cart-quantity-section > * {
  display: inline-block;
}
.order-item-header > * {
  display: inline-block;
}
.order-cart-item > * {
  display: inline-block;
}
.order-list-item > * {
  display: inline-block;
}
.order-list-item {
  box-shadow: 0px 0px 6px -1px;
  border-radius: 9px;
}
.order-image {
    height: 11rem;
    width: 11rem;
}
.order-cart-item {
  border-bottom: 1px solid lightgray;
}
.order-cart-item:last-child {
  border-bottom: none !important;
}
.cart-product-row:last-child {
  border-bottom: none !important;
}
.mobile-cart-remove-button {
  color: red;
  border: 1px solid black;
  border-radius: 11px;
  padding: 2px 5px;
  max-width: 11rem;
  margin: auto;
  font-size: 1.1rem;
}
.modal-overlay-error{
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 10000;
  cursor: pointer;
}
.buy-btn-try{
  border-radius: 25px;
    background: white;
    color: black;
    padding: 2px 20px;
    margin-top: 9px;
    font-weight: bold;
    border: none;
}
.mobile-try-buy{
@media (max-width: 700px)  {
    width: 96%;
  height: 40%;
  left: 2%;
  top: 42%;
  color: black;
  background-size: 100%;
  }
}
.course-card-icons {
  font-size: 1.5rem;
  margin-right: 1rem;
  text-align: center;
  width: 20px;
  @media (max-width: 980px) {
    font-size: 1rem;
  }
}
.course-card-detail-button {
  color: white;
  background: #7e7d7d;
  border: none;
  padding: 3px 25px;
  border-radius: 25px;
  font-size: 1.1rem;
  margin-top: 1rem;
}
.course-detail-card-button {
  min-width: 130px;
  border-radius: 25px;
  color: white;
}
.course-detail-card {
  background: #616163;
  @media (max-width: 980px) {
    border:1px solid #616163;
  }
}
.timeline-quizzes .course-detail-card{
  background: transparent;
  border: none;
}
.timeline-header {
  background: #545557;
  max-width: 190px;
  padding: .2rem 1rem;
  border-radius: 25px;
  text-align: center;
}
.timeline-left-border {
  position: absolute;
  width: 12px;
  background-color: #bdbec0;
  height: calc(100% + 10px);
  left: 0px;
  top: 15px;
}
.timeline-left-circle {
  position: absolute;
  width: 27px;
  height: 27px;
  background-color: #bdbec0;
  top: 2px;
  border-radius: 50%;
  left: -7px;
  box-shadow: 0px 0px 10px -2px black;
}
.timeline-left-line {
  position: absolute;
  width: 42px;
  height: 3px;
  background-color: #bdbec0;
  top: 14px;
  left: 0px;
}
.timeline-green-color {
  background-color: green !important;
}
.dot{
  height: 6px;
  width: 6px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
}
.black-dot .dot{
  height: 6px;
  width: 6px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
}
.black-dot .dot-circle{
  height: 6px;
  width: 6px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
}
.dot-circle{
  height: 6px;
  width: 6px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
}
.dot:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  left: 56%;
  border: 1px solid white;
  border-width: 0 0 0 1px;
  height: 56px;
  @media (min-width: 980px) and (max-width: 1200px)  {
    height: 51px !important;
  }
}
.black-dot .dot:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  left: 56%;
  border: 1px solid black;
  border-width: 0 0 0 1px;
  height: 49px;
  @media (min-width: 980px) and (max-width: 1200px)  {
    height: 51px !important;
  }
}
.ipad-pro-fontsize{
  @media (min-width: 980px) and (max-width: 1200px)  {
    font-size: 0.8rem !important;
  }
}
.loginform {
  display: flex;
  border-radius: 15px;
  box-shadow: 0 0px 7px 2px rgba(0, 0, 0, 0.1);
}
.loginformbtn {
  background-color: #fff;
  color: rgb(71, 68, 68);
  border-color: rgb(53, 50, 50);
}
.review_card {
  min-height: 360px;
  max-height: 360px;
  margin-bottom: 3rem;
}
.hiddenimp{
  display: none !important;
}
.hintmsg{
  border-left: 4px solid #efa7a7;
    color: #464646;
}
/*.hintmsg:after{
  content:"";
  background: #efa7a7;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 50px;
  width: 4px;
}*/
.succmsg{
  border-left: 4px solid #89e589;
    color: #464646;
}
/*.succmsg:after{
  content:"";
  background: #89e589;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 50px;
  width: 4px;
}*/
.invoice-download-button {
  position: absolute;
  right: 130px;
  top: 40px;
  background: #39b54a !important;
  min-width: 210px;
  z-index: 2;
}
.course-reassign-button {
  position: absolute;
  right: 130px;
  top: 90px;
  min-width: 210px;
  background: #39b54a !important;
  z-index: 2;
}
.psmbg {
  background: #fff !important;
}
.psmleaf {
  position: absolute;
  max-width: 140px;
  bottom: 0;
}
.psmbox {
  box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.1);
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-radius: 20px;
}
.dashboard-support-button {
  padding: 4px 48px;
  font-size: 19px;
  border-radius: 25px !important;
  background-color: rgb(57, 181, 74);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(57, 181, 74);
  @media (max-width: 980px) {
    padding: 4px 30px;
    font-size: inherit;
  }
}
.productimg {
  background-color: #f5bd79;
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}
.producthead:hover {
  color: #fff;
  background: #000;
  border-radius: 20px;
}
.productmeetup {
  background: #f9e193;
  padding-top: 1rem;
  padding-bottom: 1rem;
  max-width: 245px;
  min-width: 245px;
}
@media only screen and (max-width: 1145px) {
  .blogtitle {
    max-width: 236px;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1144px) {
  .blogtitle {
    max-width: 180px;
  }
}
.productabtimg {
  background: #80808021;
  min-height: 310px;
}
.productmobabt {
  @media (min-width: 681px) {
    min-height: 354px;
  }
  @media (min-width: 873px) {
    min-height: 380px;
  }
}
.subscribe-news-fixed{
  position: fixed;
  top: 115px;
  left: 0px;
  z-index: 5;
  max-width: 100%;
  background-color: rgb(245, 189, 121);
  padding-bottom: 10px !important;
}
.mob-subscribe-news{
  position: fixed;
  top: 77px;
  left: 0px;
  z-index: 5;
  max-width: 100%;
  background-color: rgb(245, 189, 121);
  padding-bottom: 10px !important;
}
.abtname {
  position: relative;
  @media (min-width: 681px) {
    position: absolute;
  }
}
.blogbtn {
  background: rgba(0, 0, 0, 0.08);
  margin-right: 15px;
  margin-left: 15px;
  border-radius: 10px;
}
.align-center{
  float: none;
    margin-left: auto;
    margin-right: auto;
}
.online-background-color{
  background: #f2f2f2 !important;
  border-radius: 5px;
}
.modal-newsletter{
  width: 45%;
  border-radius: 15px;
  padding: 0px;
  max-width: 45%;
}
.fadeMsg {
  animation: fadeOutUp 1s ease-in;
}
.active-nav-tem {
  border-bottom: 2px solid deepskyblue;
}
.conference-header-top {
  .conference-header{
    color: rgb(255, 255, 255);
  }
  .conference-header-child{
    color: white;
  }
  background-color: #283791;
}
.conference-header-scrolled {
  .conference-header{
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255) !important;
    /*transition: background-color 1s ease-in-out*/
  }
  .conference-header-child{
    color: rgb(0, 0, 0);
    /*background-color: rgb(255, 255, 255) !important;*/
    /*transition: background-color 1s ease-in-out*/
  }
  .contact_btn_light{
    color: black;
    border: 1px solid black;
  }
  background-color: rgb(255, 255, 255);
	transition: background-color 1s ease-in-out
}
.PaginationMargin ul {
	margin: 0;
}
.table td {
	vertical-align: middle;
}
.lp-calendar-grad {
	background: -webkit-linear-gradient(-45deg, #3c3535 0%, rgb(35, 40, 44) 100%);
    background: -webkit-gradient(linear, -2.73% 2.46%, 102.73% 97.54%, color-stop(0, #3c3535), color-stop(1, rgb(35, 40, 44)));
}
.quiz_card {
	@media (max-width: 1415px) {
		max-width: 28vw;
    min-width: 28vw;
	}
}
.mb-26 {
	margin-bottom: 26rem;
}
.mar-left-10{
  margin-left: 10px;
  padding: 2px;
}
.pos-video{
  position: absolute;
  top: 100px;
  left: 50%;
}
.flag-select img{
  top: 0em !important;
}
.selected--flag--option {
  font-size: 22px;
}
.flag-select .selected--flag--option .country-label{
  display: none;
}
.text-black{
  color: black;
}
.buybtn {
  box-shadow: 0 0px 5px 2px rgba(0, 0, 0, 0.1);
}
